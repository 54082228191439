import axios from "axios";
import qs from "qs";
import Vue from "vue";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: true,
});

service.interceptors.request.use(
  (config) => {
    if (config.method === "post") {
      config.data = qs.stringify(config.data);
    }
    return config;
  },
  (error) => {
    return
    // return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res && res.code) {
      if (res.code == 200) {
        return res;
      } else if (res.code == 300) {
        Vue.prototype.$toast(res.msg || res.message);
        return res;
      }
    } else
    {
      return
      // return Promise.reject(new Error(res.msg || "Error"));
    }
  },
  (error) => {
    return
    // return Promise.reject(error);
  }
);

export default service;
