import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './style/index.scss'

import 'element-ui/lib/theme-chalk/index.css'
import element from './plugins/element'
import './theme/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import MessageComponent from './components/messageDialog.vue'
import '@/permission'

Vue.use(element)

Vue.config.productionTip = false

Vue.prototype.$toast = function (message) {
  const ToastConstructor = Vue.extend(MessageComponent)
  const toastInstance = new ToastConstructor({
    propsData: { message }
  })
  toastInstance.$mount()
  document.body.appendChild(toastInstance.$el)
  toastInstance.showToast()
}
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
