import request from '@/utils/request'
// 员工列表
export function login (data) {
  return request({
    url: '/fecadmin/webcheck/login',
    method: 'post',
    data
  })
}

export function logout (data) {
  return request({
    url: '/fecadmin/webcheck/logout',
    method: 'post',
    data
  })
}

export function check (params) {
  return request({
    url: '/fecadmin/webcheck/index',
    method: 'get',
    params
  })
}

export function report (params) {
  return request({
    url: '/fecadmin/webcheck/query',
    method: 'get',
    params
  })
}
