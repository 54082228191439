<template>
  <div ref="messageTip" v-if="isVisible" class="message-container">
    <div class="message-content poa flex flex-col align-center">
      <div class="close-icon" @click="close" />
      <img class="bg no-space" src="../assets/message-bg.png" />
      <div class="text flex flex-col align-center">
        <div class="warn-icon mt-18 mb-7">
          <img src="../assets/warn-icon.png" />
        </div>
        <div class="warn-title">温馨提示</div>
        <div class="warn-text">{{ message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      defalut:
        "The detection limit for this account has been reached today. Please contact customer service for an upgrade.",
    },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  methods: {
    showToast() {
      this.isVisible = true;
      setTimeout(() => {
        this.isVisible = false;
      }, 3000);
    },
    close() {
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.message-container {
  z-index: 999;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: rgba(40, 48, 54, 0.7);
}

.message-content {
  left: 50%;
  top: 10%;
  transform: translate(-50%, 0);
  max-width: 760px;
  width: 60%;
  background: #fff;
  .bg {
    width: 100%;
  }
  .warn-icon {
    width: 108px;
    height: 104px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .text {
    width: 70%;
  }
  .warn-title {
    font-weight: bold;
    font-size: 40px;
    color: #283036;
    line-height: 57px;
    letter-spacing: 2px;
    margin-bottom: 19px;
  }
  .warn-text {
    font-size: 16px;
    color: #283036;
    line-height: 24px;
    text-align: center;
    margin-bottom: 107px;
  }
  .close-icon {
    position: absolute;
    right: -34px;
    top: -34px;
    width: 34px;
    height: 34px;
    background: url("../assets/close-icon.png") no-repeat;
    background-size: 100%;
    cursor: pointer;
  }
}

@media only screen and (max-width: 980px) {
  .message-content {
    width: 80%;
    .warn-title {
      font-size: 30px;
      line-height: 40px;
    }
    .warn-text {
      font-size: 14px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .message-content {
    width: 80%;
    .warn-icon {
      width: 50px;
      height: 50px;
    }
    .warn-title {
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 10px;
    }
    .warn-text {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 50px;
    }
    .close-icon {
      width: 20px;
      height: 20px;
      top: -20px;
      right: -20px;
    }
  }
}
</style>
