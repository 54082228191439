import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/index')
  }
]

export const asyncRoutes = [
  {
    path: '/check',
    name: 'check',
    component: () => import('@/views/check')
  },
  {
    path: '/report',
    name: 'report',
    component: () => import('@/views/report')
  },
  {
    path: '*',
    redirect: '/'
  }
]

const createRouter = () => new Router({
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
})
const originalPush = Router.prototype.push
Router.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = createRouter()

export function resetRouter () {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
