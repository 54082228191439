import router from "./router"
import store from './store'
import { asyncRoutes, constantRoutes } from '@/router'

import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
  showSpinner: false
})

const whiteList = ['/']

let accessRoutes = []
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  document.title = 'ScanDefender'
  const hasRole = store.state.user.userInfo
  if (hasRole) {
    if (accessRoutes.length) {
      next()
    } else {
      accessRoutes = [...constantRoutes, ...asyncRoutes]
      router.addRoutes(accessRoutes)
      next({ ...to, replace: true })
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
