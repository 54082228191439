import {
  Input,
  Dialog,
  Form,
  FormItem,
  Row,
  Col,
  Tooltip
} from 'element-ui'
const element = {
  install: function (Vue) {
    Vue.use(Input)
    Vue.use(Dialog)
    Vue.use(Form)
    Vue.use(FormItem)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Tooltip)
  }
}
export default element
