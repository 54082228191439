import { login, logout } from '@/api/index'
import { setWithExpiry, getWithExpiry } from '@/utils/index'

const state = {
  userInfo: getWithExpiry('UserInfo')
}
const mutations = {
  SET_USER_INFO: (state, info) => {
    state.userInfo = info
  }
}

const actions = {
  // user login
  login ({ commit }, userInfo) {
    const { mobile, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ mobile: mobile.trim(), password }).then(response => {
        if (response.code === 200) {
          const { data } = response
          commit('SET_USER_INFO', { ...data, exexpiry: new Date().getTime() + 1 * 24 * 60 * 60 * 1000 })
          setWithExpiry('UserInfo', data, 1 * 24 * 60 * 60 * 1000)
        }
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      logout({ mobile: state.userInfo.mobile }).then(() => {
        localStorage.removeItem('UserInfo')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
