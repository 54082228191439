export function setWithExpiry(key, val, ttl) {
  const now = new Date()
  const item = typeof val === 'string'
    ? {
        websit: val,
        expiry: now.getTime() + ttl
      }
    : {
        ...val,
        expiry: now.getTime() + ttl
      }
  localStorage.setItem(key, JSON.stringify(item))
}

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key)
  if (!itemStr) return null
  const item = JSON.parse(itemStr)
  const now = new Date()
  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key)
    return null
  }
  return item
}
